@import url(https://fonts.googleapis.com/css2?family=Old+Standard+TT:ital@0;1&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  background-color: #ffecff !important;
}

p {
  font-family: "Old Standard TT";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #ed0d6b;
  margin-bottom: 30px;

  @media (max-width: 960px) {
    font-size: 16px;
  }

  /*text-shadow: 5px 3px 12px #FFECFF;*/

  /*background-color: white;*/
}

:root {
  @media (max-width: 960px) {
    font-size: 16px !important;
  }
}

p:last-child {
  margin-bottom: 20px;
}

h3 {
  font-family: "Old Standard TT";
  font-style: italic;
  font-weight: normal;
  font-size: 24px;
  color: #ed0d6b;
  text-shadow: 5px 3px 12px rgba(237, 13, 107, 0.75);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  /*outline:1px solid #d6d6d6;*/
}

/*font-family: 'Old Standard TT', serif;*/

/*font-family: 'Old Standard TT', serif;*/
/*font-family: 'Open Sans', sans-serif;*/

main canvas {
  width: 100vw;
  position: fixed;
}

main {
  position: relative;
  height: 100%;
}

main canvas {
  height: 100vh;
  top: 0;
  left: 0;
}

.hooks-main > svg {
  display: none;
}

.hooks-main > div {
  position: absolute;
  will-change: transform;
  border-radius: 50%;
  background: lightcoral;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  opacity: 0.6;
}

.hooks-main > div:nth-child(1) {
  width: 120px;
  height: 120px;
}

.hooks-main > div:nth-child(2) {
  width: 250px;
  height: 250px;
}

.hooks-main > div:nth-child(3) {
  width: 150px;
  height: 150px;
}

.hooks-main > div::after {
  content: "";
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
}

.hooks-main > div:nth-child(2)::after {
  top: 70px;
  left: 70px;
  width: 70px;
  height: 70px;
}

.hooks-main > div:nth-child(3)::after {
  top: 50px;
  left: 50px;
  width: 50px;
  height: 50px;
}

.hooks-main {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-filter: url("#goo");
          filter: url("#goo");
  overflow: hidden;
}

.st3 {
  fill: none;
  stroke: #ed0d6b;
  stroke-width: 1.8;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st4 {
  fill: none;
  stroke: #ed0d6b;
  stroke-width: 1.8;
  stroke-linecap: round;
  stroke-linejoin: round;
  /*stroke-miterlimit: 10;*/
  /*stroke-dasharray: 200;*/
  /*stroke-dasharray: 1000;*/
  /*stroke-dashoffset: 0;*/
  /*animation: dash 30s linear ;*/

  /*stroke-dasharray: 1000;*/
  /*stroke-dashoffset: 1000;*/
  /*animation: dash 30s linear alternate infinite;*/
  /*animation: dash 5s linear;*/
}
.st5 {
  fill: #ed0d6b;
}

.path {
  stroke-dasharray: 150;
  animation: dash 40s linear alternate-reverse;
}

@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 1000;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 1000;
  }
}

/*@keyframes dash {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 1000;
  }
}*/

/*@keyframes dash {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 822;
  }
}*/
/*!*@keyframes dash {*/
/*  to {*/
/*    stroke-dashoffset: 1000;*/
/*  }*/
/*}*!*/
/*
@keyframes dash {
  0%{
    stroke-dasharray: 112, 2000; !* initial state *!
  }
  25%{
    stroke-dasharray: 112, 2000;
  }
  50%{
    stroke-dasharray: 600, 2000; !* turns into a circle *!
  }
  75%{
    stroke-dasharray: 600, 2000; !* back at initial state *!
  }
  100%{
    stroke-dasharray: 112, 2000;
  }
}
*/

.rhap_container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    line-height: 1;
    font-family: inherit;
    width: 100%;
    padding: 10px 15px;
    /*z-index: 1000;*/
    /*background-color: #fff;*/
    /*box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);*/
}
.rhap_container:focus:not(:focus-visible) {
    outline: 0;
}
.rhap_container svg {
    vertical-align: baseline;
    vertical-align: initial;
}

.rhap_header {
    margin-bottom: 10px;
}

.rhap_footer {
    margin-top: 5px;
}

.rhap_main {
    display: flex;
    /*flex-direction: column;*/
    flex: 1 1 auto;
}

.rhap_stacked .rhap_controls-section {
    /*margin-top: 8px;*/
}

.rhap_horizontal {
    flex-direction: row;
}
.rhap_horizontal .rhap_controls-section {
    margin-left: 8px;
}

.rhap_horizontal-reverse {
    flex-direction: row-reverse;
}
.rhap_horizontal-reverse .rhap_controls-section {
    margin-right: 8px;
}

.rhap_stacked-reverse {
    flex-direction: column-reverse;
}
.rhap_stacked-reverse .rhap_controls-section {
    margin-bottom: 8px;
}

.rhap_progress-section {
    order:2;
    display: flex;
    flex: 3 1 auto;
    align-items: center;
}

.rhap_progress-container {
    display: flex;
    align-items: center;
    height: 20px;
    flex: 1 0 auto;
    align-self: center;
    margin: 0 calc(10px + 1%);
    cursor: pointer;
    -webkit-user-select: none;
}
.rhap_progress-container:focus:not(:focus-visible) {
    outline: 0;
}

.rhap_time {
    display: none;
    color: #333;
    font-size: 16px;
    -ms-user-select: none;
        user-select: none;
    -webkit-user-select: none;
}

.rhap_progress-bar {
    box-sizing: border-box;
    position: relative;
    z-index: 0;
    width: 100%;
    height: 5px;
    background-color: #fc8fbc;
    border-radius: 2px;
}

.rhap_progress-filled {
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: #ED0D6B;
    border-radius: 2px;
}

.rhap_progress-bar-show-download {
    background-color: rgba(221, 221, 221, 0.5);
}

.rhap_download-progress {
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: #fc8fbc;
    border-radius: 2px;
}

.rhap_progress-indicator {
    box-sizing: border-box;
    position: absolute;
    z-index: 3;
    width: 20px;
    height: 20px;
    margin-left: -10px;
    top: -8px;
    background: #868686;
    border-radius: 50px;
    box-shadow: rgba(134, 134, 134, 0.5) 0 0 5px;
}

.rhap_controls-section {
    display: flex;
    /*flex: 1 1 auto;*/
    justify-content: space-between;
    align-items: center;
}

.rhap_additional-controls {
    display: none;
    flex: 1 0 auto;
    align-items: center;
}

.rhap_repeat-button {
    font-size: 26px;
    width: 26px;
    height: 26px;
    color: #868686;
    margin-right: 6px;
}

.rhap_main-controls {
    flex: 0 1 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rhap_main-controls-button {
    margin: 0 3px;
    color: #ED0D6B;
    font-size: 35px;
    width: 35px;
    height: 35px;
}

.rhap_play-pause-button {
    font-size: 40px;
    width: 40px;
    height: 40px;
}

.rhap_volume-controls {
    display: none;
    flex: 1 0 auto;
    justify-content: flex-end;
    align-items: center;
}

.rhap_volume-button {
    flex: 0 0 26px;
    font-size: 26px;
    width: 26px;
    height: 26px;
    color: #868686;
    margin-right: 6px;
}

.rhap_volume-container {
    display: flex;
    align-items: center;
    flex: 0 1 100px;
    -webkit-user-select: none;
}

.rhap_volume-bar-area {
    display: flex;
    align-items: center;
    width: 100%;
    height: 14px;
    cursor: pointer;
}
.rhap_volume-bar-area:focus:not(:focus-visible) {
    outline: 0;
}

.rhap_volume-bar {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 4px;
    background: #dddddd;
    border-radius: 2px;
}

.rhap_volume-indicator {
    box-sizing: border-box;
    position: absolute;
    width: 12px;
    height: 12px;
    margin-left: -6px;
    left: 0;
    top: -4px;
    background: #ED0D6B;
    opacity: 0.9;
    border-radius: 50px;
    box-shadow: rgba(134, 134, 134, 0.5) 0 0 3px;
    cursor: pointer;
}
.rhap_volume-indicator:hover {
    opacity: 0.9;
}

/* Utils */
.rhap_button-clear {
    background-color: transparent;
    border: none;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
    transform: scale(0.65);
}
.rhap_button-clear:hover {
    opacity: 0.9;
    transition-duration: 0.2s;
}
.rhap_button-clear:active {
    opacity: 0.95;
}
.rhap_button-clear:focus:not(:focus-visible) {
    outline: 0;
}

