@import url("https://fonts.googleapis.com/css2?family=Old+Standard+TT:ital@0;1&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
  background-color: #ffecff !important;
}

p {
  font-family: "Old Standard TT";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #ed0d6b;
  margin-bottom: 30px;

  @media (max-width: 960px) {
    font-size: 16px;
  }

  /*text-shadow: 5px 3px 12px #FFECFF;*/

  /*background-color: white;*/
}

:root {
  @media (max-width: 960px) {
    font-size: 16px !important;
  }
}

p:last-child {
  margin-bottom: 20px;
}

h3 {
  font-family: "Old Standard TT";
  font-style: italic;
  font-weight: normal;
  font-size: 24px;
  color: #ed0d6b;
  text-shadow: 5px 3px 12px rgba(237, 13, 107, 0.75);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  /*outline:1px solid #d6d6d6;*/
}

/*font-family: 'Old Standard TT', serif;*/

/*font-family: 'Old Standard TT', serif;*/
/*font-family: 'Open Sans', sans-serif;*/

main canvas {
  width: 100vw;
  position: fixed;
}

main {
  position: relative;
  height: 100%;
}

main canvas {
  height: 100vh;
  top: 0;
  left: 0;
}

.hooks-main > svg {
  display: none;
}

.hooks-main > div {
  position: absolute;
  will-change: transform;
  border-radius: 50%;
  background: lightcoral;
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.75);
  opacity: 0.6;
}

.hooks-main > div:nth-child(1) {
  width: 120px;
  height: 120px;
}

.hooks-main > div:nth-child(2) {
  width: 250px;
  height: 250px;
}

.hooks-main > div:nth-child(3) {
  width: 150px;
  height: 150px;
}

.hooks-main > div::after {
  content: "";
  position: absolute;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.8);
}

.hooks-main > div:nth-child(2)::after {
  top: 70px;
  left: 70px;
  width: 70px;
  height: 70px;
}

.hooks-main > div:nth-child(3)::after {
  top: 50px;
  left: 50px;
  width: 50px;
  height: 50px;
}

.hooks-main {
  position: absolute;
  width: 100%;
  height: 100%;
  filter: url("#goo");
  overflow: hidden;
}

.st3 {
  fill: none;
  stroke: #ed0d6b;
  stroke-width: 1.8;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st4 {
  fill: none;
  stroke: #ed0d6b;
  stroke-width: 1.8;
  stroke-linecap: round;
  stroke-linejoin: round;
  /*stroke-miterlimit: 10;*/
  /*stroke-dasharray: 200;*/
  /*stroke-dasharray: 1000;*/
  /*stroke-dashoffset: 0;*/
  /*animation: dash 30s linear ;*/

  /*stroke-dasharray: 1000;*/
  /*stroke-dashoffset: 1000;*/
  /*animation: dash 30s linear alternate infinite;*/
  /*animation: dash 5s linear;*/
}
.st5 {
  fill: #ed0d6b;
}

.path {
  stroke-dasharray: 150;
  animation: dash 40s linear alternate-reverse;
}

@keyframes dash {
  to {
    stroke-dashoffset: 1000;
  }
}

/*@keyframes dash {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 1000;
  }
}*/

/*@keyframes dash {
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 822;
  }
}*/
/*!*@keyframes dash {*/
/*  to {*/
/*    stroke-dashoffset: 1000;*/
/*  }*/
/*}*!*/
/*
@keyframes dash {
  0%{
    stroke-dasharray: 112, 2000; !* initial state *!
  }
  25%{
    stroke-dasharray: 112, 2000;
  }
  50%{
    stroke-dasharray: 600, 2000; !* turns into a circle *!
  }
  75%{
    stroke-dasharray: 600, 2000; !* back at initial state *!
  }
  100%{
    stroke-dasharray: 112, 2000;
  }
}
*/
